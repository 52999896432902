export default [
  {
    prop: 'id',
    label: 'STT',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'title',
    label: 'Tiêu đề',
    minWidth: 250,
    type: 'text'
  },
  {
    prop: 'link',
    label: 'Link',
    minWidth: 250,
    type: 'text'
  }
]

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12 card p-0" }, [
      _c("div", { staticClass: "card-body row p-0" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("my-filter", {
              attrs: { rules: _vm.rules },
              on: { "filter-change": _vm.updateFilter },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("my-table", {
              attrs: {
                columnDefs: _vm.columnDefs,
                "data-rows": _vm.menusData,
                actions: _vm.actions,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }